<template>
  <v-app>
    <v-main>
      <v-container>
        <navbar />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/*eslint-disable */

export default {
  name: "Index",
  components: {
    Navbar: () => import("@/components/core/Navbar"),
  },
};
</script>
